.App {
  text-align: center;
  background: linear-gradient(315deg, #1F0042 0%, #840490 110%);
}

.containerMain > .button {
  grid-column: 3;
  grid-row: 1;
  align-self: baseline;
  justify-self: right;
}

@media (max-width: 1190px) {
  .containerMain > .button {
    grid-column: 2;
    align-self: baseline;
    justify-self: center;
  }
}

ul.containerBreadcrumb {
  grid-column: 2;
  align-self: end;
  padding: 10px 16px;
  list-style: none;
}

ul.containerBreadcrumb li{
  display: inline;
  font-size: 16px;
}

ul.containerBreadcrumb li+li:before {
  padding: 8px;
  color: #ffffff23;
  content: "/\00a0";
}

ul.containerBreadcrumb li a {
  color: #ffffff23;
  text-decoration: none;
}

.containerMain > .containerText {
  grid-column: 2;
  grid-row: 1;
  align-self: end;
}

@media (max-width: 1190px) {
  .containerMain > .containerText {
    grid-column-start: 1;
    grid-column-end: 4;
    align-self: end;
    justify-self: center;
  }
}
