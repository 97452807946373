@font-face {
  font-family: "MokotoGlitch";
  src: local("MokotoGlitch"), url(./fonts/MokotoGlitch.ttf) format(truetype);
}

@font-face {
  font-family: "MokotoGlitch2";
  src: local("MokotoGlitch2"), url(./fonts/MokotoGlitch2.ttf) format(truetype);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.containerMain {
  height: 100vh;
  width: 100vw;
  display: grid;
  gap: .5rem;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr max-content 1fr;
  align-items: center;
  justify-content: center;
}

.containerBlur:before {
  content: '';
  position: absolute;
  top: -25;
  right: 0;
  bottom: 0;
  left: -25;
  backdrop-filter: blur(1px);
}
.containerBlur {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.05);  
  border: 1px solid rgba(255, 255, 255, .01);
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: .75rem;
  padding-right: .75rem;
  overflow: hidden;
  border-radius: 1rem;
}

@media (max-width: 1190px) {
  .containerBlur {
    grid-column: span 3;
  }
}

.containerButton {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (max-width: 1190px) {
  .containerButton {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.button {
  width: fit-content;
  font-size: 16px;
  padding-top: .75em;
  padding-bottom: .75em;
  padding-right: 1.25em;
  padding-left: 1.25em;
  margin: .5em;
  background-color: rgba(12, 12, 12, .6);
  color: white;
  border: 0px;
  border-radius: .35em;
}

.button:hover {
  background-color: rgba(12, 12, 12, .5);
}

.button:active {
  background-color: rgba(12, 12, 12, .9);
}

.button:disabled {
  background-color: rgba(12, 12, 12, .4);
  color: #ffffff23;
}

.rektInput {
  font-size: 16px;
  background-color: rgba(12, 12, 12, .4);
  border-color: #ffffff00;
  color: white;
  height: 2.5em;
  width: 100%;
  margin: .25em;
  border-radius: .35em;
  padding-left: .25em;
  padding-right: .25em;
}

.rektInput::placeholder {
  color: #ffffff23;
}

.titleText {
  font-size: 2.5rem;
  color: white;
  margin: .5rem;
}

@media (max-width: 1190px) {
  .titleText {
    font-size: 1.8rem;
  }
}

.containerText {
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
}

#containerToast {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.itemToast {
  width: auto;
  background-color: rgba(12, 12, 12, .6);
  color: white;
  border-radius: .35rem;
  padding-top: .75em;
  padding-bottom: .75em;
  padding-right: 1.25em;
  padding-left: 1.25em;
  margin: .5rem;
  border: 0;
}

.fadeIn {
  animation: fadeIn linear .5s;
}

.fadeOut {
  animation: fadeOut linear .5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    max-height: 0px;
  }

  100% {
    opacity: 1;
    max-height: 100px;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    max-height: 110px;
  }

  100% {
    opacity: 0;
    max-height: 0px;
  }
}